<template>
  <sign-page
    :title-text="title"
    :request="request"
    table-size="large"
    ref="activityTypePage"
    :titleMenus="[{key: 'new', label: '开展新活动'}]"
    @clickTitleMenu="clickTitleMenu"
    @tableAction="tableAction"
    :column-list="columnList"
    :table-actions-fixed="true"
    :tableActions="tableActions"
    :tableActionsWidth="200"
    :need-data-file="true">
    <fm-modal :mask-closable="false" v-model="modal" v-if="modal" width="90%" title="活动明细">
      <activity-form
        :source-data="chooseData"
        ref="activityForm"
        :activity-type-list="activityTypeList"
        :user-list="$store.getters.userList">
      </activity-form>
      <div class="btns">
        <fm-btn @click="save" style="margin-right:50px;">保存</fm-btn>
        <fm-btn @click="modal = false">取消</fm-btn>
      </div>
    </fm-modal>
    <fm-form-dialog
      form-title="开展新活动"
      submitBtnLabel="下一步"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="chooseData"
      :mask-closable="false"
      label-alone
      label-align="left"
      form-width="600px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
    <fm-form-dialog
      v-if="action"
      :form-title="tableActions.find(v => v.key === action).label"
      submitBtnLabel="确定"
      :open-dialog.sync="openDialogStatus"
      :form-parms="formParmsStatus"
      :old-data="chooseData"
      :mask-closable="false"
      label-alone
      label-align="left"
      form-width="400px"
      @formSubmit="formSubmitStatus"
      @handleClose="openDialogStatus = false">
    </fm-form-dialog>
  </sign-page>
</template>

<script>
import {
  activityRequest as request,
  activityTypeRequest
} from '../../api'

import ActivityForm from './cmp/form'

import {
  dateOperating
} from '@/fmlib'

export default {
  components: {
    ActivityForm
  },
  props: {
    activityTypeId: {
      type: Number
    }
  },
  computed: {
    formParms () {
      return [{
        type: 'select',
        label: '活动类型',
        selectDatas: this.activityTypeList.map(v => {
          return {
            key: v.id,
            label: v.name
          }
        }),
        key: 'activityTypeId',
        check: {
          required: true
        }
      }]
    },
    formParmsStatus () {
      let data = [{
        type: 'datePicker',
        label: '开始时间',
        key: 'startDate'
      },
      {
        type: 'datePicker',
        label: '结束时间',
        key: 'endDate'
      }]
      data = this.action === 'start' ? [data[0]] : [data[1]]
      return data
    },
    tableActions () {
      return [{
        key: 'detail',
        label: '活动明细'
      },
      {
        key: 'update',
        label: '修改'
      },
      {
        key: 'start',
        label: '开始',
        show (data) {
          return !data.status || data.status === 'wait'
        }
      },
      {
        key: 'end',
        label: '结束',
        show (data) {
          return data.status === 'doing'
        }
      },
      {
        key: 'restart',
        label: '重新开展',
        show (data) {
          return data.status === 'end'
        }
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    columnList () {
      let data = [{
        field: 'activityTypeName',
        title: '类型'
      },
      {
        field: 'name',
        title: '名称'
      },
      {
        field: 'startDate',
        title: '开始时间',
        render: (h, rowData) => {
          return h('div', rowData && rowData.startDate ? rowData.startDate.slice(0, 10) : '-')
        }
      },
      {
        field: 'endDate',
        title: '结束时间',
        render: (h, rowData) => {
          return h('div', rowData && rowData.endDate ? rowData.endDate.slice(0, 10) : '-')
        }
      },
      {
        field: 'statusText',
        title: '状态'
      }]
      return this.activityTypeId ? data.slice(1) : data
    }
  },
  data () {
    return {
      action: null,
      activityTypeList: [],
      modal: false,
      title: '党建+管理',
      openDialog: false,
      openDialogStatus: false,
      chooseData: {},
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  },
  created () {
    this.$store.dispatch('loadUserList')
    this.$store.dispatch('loadOrgList')
    this.loadTypes()
  },
  methods: {
    async formSubmitStatus (data, r) {
      if (this.action === 'start') {
        let startDate = data.startDate ? (data.startDate instanceof Date ? dateOperating.computeDay({days: 0, date: data.startDate, format: 'yy-mm-dd hh:mm:ss'}) : data.startDate) : null
        this.updateStatus({
          status: 'doing',
          startDate
        })
      } else if (this.action === 'end') {
        let endDate = data.endDate ? (data.endDate instanceof Date ? dateOperating.computeDay({days: 0, date: data.endDate, format: 'yy-mm-dd hh:mm:ss'}) : data.endDate) : null
        this.updateStatus({
          status: 'end',
          endDate
        })
      }
      r()
      this.openDialogStatus = false
    },
    async formSubmit (data, r) {
      this.chooseData = data
      this.chooseData.activityType = this.activityTypeList.find(v => v.id === data.activityTypeId)
      this.openDialog = false
      this.modal = true
      r()
    },
    async loadTypes () {
      let data = await activityTypeRequest.get()
      this.activityTypeList = data.filter(v => v.id > 11)
      if (this.activityTypeId) {
        this.title = (this.activityTypeList.find(v => v.id === this.activityTypeId) || {name: '活动'}).name + '管理'
      }
    },
    async loadData (parm) {
      parm = parm || {}
      if (this.activityTypeId) {
        parm.activityTypeId = this.activityTypeId
      }
      let datas = await request.get(parm) 
      datas.forEach(v => {
        v.activityTypeName = v.activityType ? v.activityType.name : null
        v.statusText = (this.$store.getters.activityStatusList.find(v1 => v.status === v1.key) || this.$store.getters.activityStatusList[0]).label
      })
      datas = datas.filter(v => v.activityTypeId > 11)
      return datas
    },
    async tableAction (parm) {
      this.chooseData = Object.assign({}, parm.data)
      if (parm.action === 'detail') {
        this.$router.push({
          name: 'hrms.activity.detail',
          query: {
            type: 'manage',
            id: this.chooseData.id
          }
        })
      } else if (parm.action === 'update') {
        this.modal = true
      } else if (parm.action === 'start') {
        this.action = parm.action
        this.openDialogStatus = true
      } else if (parm.action === 'end') {
        this.action = parm.action
        this.openDialogStatus = true
      } else if (parm.action === 'restart') {
        this.action = parm.action
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定重新开展该活动吗?'})
        if (result) {
          this.updateStatus({
            status: 'wait'
          })
        }
      }
    },
    async updateStatus (parm) {
      parm.activityTypeId = this.chooseData.activityTypeId
      await request.update(this.chooseData.id, parm)
      this.$refs.activityTypePage.loadData()
    },
    async save () {
      let data = this.$refs.activityForm.getFormData()
      if (!data) {
        return
      }
      if (data.id) {
        await request.update(data.id, data)
      } else {
        await request.add(data)
      }
      this.$refs.activityTypePage.loadData()
      this.modal = false
    },
    clickTitleMenu () {
      if (!this.activityTypeId) {
        this.openDialog = true
        this.chooseData = {}
      } else {
        this.chooseData = {
          activityTypeId: this.activityTypeId
        }
        this.chooseData.activityType = this.activityTypeList.find(v => v.id === this.activityTypeId)
        this.modal = true
      }
    }
  }
}
</script>

<style scoped lang="less">
.btns {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
